import { RootState } from "../rootReducer";
import { TRemoteConfig } from "./remoteConfiguration.reducer";

export const remoteConfigurationSelector = (state: RootState): TRemoteConfig =>
  state.remoteConfig.data || {};

export const isLoadingRemoteConfigurationSelector = (
  state: RootState
): boolean => state.remoteConfig.loading;

export const neverLoadedRemoteConfigurationSelector = (
  state: RootState
): boolean => state.remoteConfig.neverLoaded;

export const isLiveChatEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_LIVE_CHAT;

export const usdToGbpExchangeRate = (state: RootState): number =>
  state.remoteConfig.data.USD_GBP_EXCHANGE_RATE;

export const isIncomeDecisionEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_INCOME_DECISION;

export const isLiquidationEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_LIQUIDATION;

export const isMandatoryQuestionsEnabledSelector = (
  state: RootState
): boolean => state.remoteConfig.data.FF_MANDATORY_QUESTIONS;

export const isPortfolioVisibilityCardEnabledSelector = (
  state: RootState
): boolean => state.remoteConfig.data.FF_PORTFOLIO_VISIBILITY_CARD;

export const isLiquidationFeesEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_LIQUIDATION_FEES;

export const isMarketingEmailPreferencesEnabledSelector = (
  state: RootState
): boolean => state.remoteConfig.data.FF_MARKETING_EMAIL_PREFERENCES;

export const isEmailVerificationEnabledSelector = (state: RootState): boolean =>
  state.remoteConfig.data.FF_EMAIL_VERIFICATION;
