import AuthActionTypes from "./auth.types";
import {
  getUserInfoAsync,
  resetPasswordAsync,
  signInAppleAsync,
  signInAsync,
  signInGoogleAsync,
  signOutAsync,
  updateTwoFactorAuthAsync,
  signupAppleAsync,
} from "./auth.actions";
import { authError } from "../../components/Login/actions";

export interface AuthState {
  error: any;
  loading: boolean;
  isAuthenticated: boolean;
  isTwoFactorAuthEnabled: boolean;
  primaryTwoFactorAuthMethod?: string;
  accountStatus: string;
  viewedInvestorCertificateForm: boolean;
  neverLoaded: boolean;
  id?: string;
  email?: string;
  mobileNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  isEmailVerified: boolean;
}

export const INITIAL_STATE: AuthState = {
  error: null,
  loading: false,
  isAuthenticated: false,
  isTwoFactorAuthEnabled: false,
  accountStatus: "",
  viewedInvestorCertificateForm: false,
  neverLoaded: true,
  isEmailVerified: false,
};

const authReducer = (state = INITIAL_STATE, action: any): AuthState => {
  switch (action.type) {
    case signInAsync.pending.type:
    case signInGoogleAsync.pending.type:
    case signOutAsync.pending.type:
    case getUserInfoAsync.pending.type:
    case resetPasswordAsync.pending.type:
    case updateTwoFactorAuthAsync.pending.type:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case signInAsync.fulfilled.type:
      return {
        ...state,
        error: null,
        loading: false,
        isAuthenticated: true,
      };
    case authError.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case getUserInfoAsync.fulfilled.type:
      return {
        ...state,
        ...action.payload,
        loading: false,
        isAuthenticated: true,
        neverLoaded: false,
      };
    case resetPasswordAsync.fulfilled.type:
    case updateTwoFactorAuthAsync.fulfilled.type:
      return {
        ...state,
        loading: false,
      };
    case resetPasswordAsync.rejected.type:
    case updateTwoFactorAuthAsync.rejected.type:
    case AuthActionTypes.REFRESH_SESSION_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case signInAsync.rejected.type:
    case signOutAsync.rejected.type:
    case AuthActionTypes.AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isAuthenticated: false,
        viewedInvestorCertificateForm: false,
      };
    case getUserInfoAsync.rejected.type:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        viewedInvestorCertificateForm: false,
      };
    case AuthActionTypes.CLEAR_ERROR_AND_RESULT:
      return {
        ...state,
        error: null,
      };
    case AuthActionTypes.SET_VIEWED_INVESTOR_CERTIFICATE_FORM:
      return {
        ...state,
        viewedInvestorCertificateForm: true,
      };
    case AuthActionTypes.UPDATE_USER:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        neverLoaded: false,
      };
    case AuthActionTypes.RESET:
      return INITIAL_STATE;
    case signInAppleAsync.fulfilled.type: {
      const isPrivateRelayEmail = action.payload.isPrivateRelayEmail;
      const isAuthenticated = isPrivateRelayEmail ? false : true;
      return {
        ...state,
        ...action.payload,
        loading: false,
        isAuthenticated,
        neverLoaded: false,
      };
    }
    case signupAppleAsync.fulfilled.type:
    case signInGoogleAsync.fulfilled.type:
      return {
        ...state,
        ...action.payload,
        loading: false,
        isAuthenticated: true,
        neverLoaded: false,
      };
    default:
      return state;
  }
};

export default authReducer;
