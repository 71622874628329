import { CommitmentPendingPayment } from "@ifgengineering/client-invest-sdk";
import {
  acknowledgePaymentAPI,
  fetchPendingPaymentsAPI,
} from "./payment.actions";
import { signInAsync, signOutAsync } from "@state/auth/auth.actions";

export interface PaymentState {
  neverLoadedPayments: boolean;
  loading: boolean;
  data: CommitmentPendingPayment[];
}

export const INITIAL_STATE: PaymentState = {
  neverLoadedPayments: true,
  loading: false,
  data: [],
};

const paymentReducer = (state = INITIAL_STATE, action: any): PaymentState => {
  switch (action.type) {
    case signInAsync.fulfilled.type:
    case signOutAsync.fulfilled.type:
      return {
        ...INITIAL_STATE,
      };
    case acknowledgePaymentAPI.pending.type:
    case fetchPendingPaymentsAPI.pending.type:
      return {
        ...state,
        loading: true,
      };
    case fetchPendingPaymentsAPI.fulfilled.type:
      return {
        ...state,
        loading: false,
        neverLoadedPayments: false,
        data: [...action.payload],
      };
    default:
      return state;
  }
};

export default paymentReducer;
