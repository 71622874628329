import React, { FC } from "react";
import { Banner } from "@ifgengineering/component-library";

interface Props {
  onClickCTA?: VoidFunction;
}

export const VerifyAccountBanner: FC<Props> = ({ onClickCTA }) => (
  <Banner
    text="You need to verify your account to proceed with investing."
    CTAText="Verify Account"
    onClickCTA={onClickCTA}
    testId="verify-account-banner"
  />
);

export default VerifyAccountBanner;
