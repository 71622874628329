import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import {
  fetchAndActivate,
  getBoolean,
  getNumber,
  getRemoteConfig,
} from "firebase/remote-config";
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  VERSION,
} from "gatsby-env-variables";
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const initFirebase = async () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "notification_received");
  setUserProperties(analytics, { web_app_version: VERSION });

  const remoteConfig = getRemoteConfig(app);
  try {
    await fetchAndActivate(remoteConfig);

    const config = {
      FF_LIVE_CHAT: getBoolean(remoteConfig, "FF_LIVE_CHAT"),
      USD_GBP_EXCHANGE_RATE: getNumber(remoteConfig, "USD_GBP_EXCHANGE_RATE"),
      FF_INCOME_DECISION: getBoolean(remoteConfig, "FF_INCOME_DECISION"),
      FF_LIQUIDATION: getBoolean(remoteConfig, "FF_LIQUIDATION"),
      FF_MANDATORY_QUESTIONS: getBoolean(
        remoteConfig,
        "FF_MANDATORY_QUESTIONS"
      ),
      FF_PORTFOLIO_VISIBILITY_CARD: getBoolean(
        remoteConfig,
        "FF_PORTFOLIO_VISIBILITY_CARD"
      ),
      FF_LIQUIDATION_FEES: getBoolean(remoteConfig, "FF_LIQUIDATION_FEES"),
      FF_MARKETING_EMAIL_PREFERENCES: getBoolean(
        remoteConfig,
        "FF_MARKETING_EMAIL_PREFERENCES"
      ),
      FF_EMAIL_VERIFICATION: getBoolean(remoteConfig, "FF_EMAIL_VERIFICATION"),
    };

    return config;
  } catch (error) {
    console.error("Error fetching Remote Config:", error);
    return null;
  }
};

export default initFirebase;
