import { RootState } from "../rootReducer";
import { TNotification } from "@ifgengineering/client-notifications-sdk";

export const getIsLoadingNotificationSelector = (state: RootState): boolean =>
  state.notification.loading;

export const getNotificationsSelector = (state: RootState): TNotification[] =>
  state.notification.data.filter(
    // @ts-expect-error TODO: update notification type in sdk
    (notification) => notification.template.type !== "oneTimeMessage"
  );

export const getUnreadOneTimeMessageNotificationSelector = (
  state: RootState
): TNotification | undefined => {
  return state.notification.data.find(
    (notification) =>
      // @ts-expect-error TODO: update notification type in sdk
      notification.template.type === "oneTimeMessage" &&
      notification.status === "UNREAD"
  );
};

export const getPendingNotificationSelector = (state: RootState): boolean => {
  return state.notification.data.some(
    (notification) => notification.status === "UNREAD"
  );
};

export const neverLoadedNotificationSelector = (state: RootState): boolean =>
  state.notification.neverLoadedNotifications;
