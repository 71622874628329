import { AxiosPromise, AxiosStatic } from "axios";
import { SignupInput } from "../types";

const signup =
  (serverURL: string, versionURLFragment: string, appAxios: AxiosStatic) =>
  async ({
    email,
    password,
    captchaToken,
    origin,
  }: SignupInput): AxiosPromise<void> => {
    const result = await appAxios.post(
      `${serverURL}/auth${versionURLFragment}/signup-v2`,
      {
        email,
        password,
        captchaToken,
        origin,
      },
      {
        withCredentials: true,
      },
    );

    return result;
  };

export default signup;
