import useAnalytics from "@helpers/useAnalytics";
import { WEALTH_SITE_URL, SITE_URL } from "gatsby-env-variables";
import {
  MenuTemplateItem,
  NavMenuItemsPosition,
} from "@ifgengineering/client-app-navbar";

import { navigate as outerNav } from "@reach/router";
import { navigate as innerNav } from "gatsby";

const fireAppSwitchAnalytics = useAnalytics("appSwitch");

export const navItems: MenuTemplateItem[] = [
  {
    tab: "cur8",
    label: "Cur8",
    selected: true,
    position: NavMenuItemsPosition.MAIN,
    onClick: async (): Promise<void> => {
      (await fireAppSwitchAnalytics)("Cur8");
      await innerNav(`${SITE_URL}/app/deals`);
    },
  },
  {
    tab: "tools",
    label: "Tools",
    selected: false,
    position: NavMenuItemsPosition.MAIN,
    onClick: async (): Promise<void> => {
      (await fireAppSwitchAnalytics)("Wealth");
      outerNav(`${WEALTH_SITE_URL}/app/compare?view=tools`);
    },
  },
  {
    tab: "help",
    label: "Help",
    selected: false,
    position: NavMenuItemsPosition.SECONDARY,
    onClick: () => outerNav(`${WEALTH_SITE_URL}/app/compare?view=help`),
  },
];
