import { VerifyEmailV2Input } from "../types";
import { AxiosStatic } from "axios";

const verifyEmail =
  (serverURL: string, appAxios: AxiosStatic) =>
  async ({ email, otp }: VerifyEmailV2Input): Promise<void> => {
    return await appAxios.post(
      `${serverURL}/auth/verify-email-v2`,
      {
        email,
        otp,
      },
      { withCredentials: true },
    );
  };

export default verifyEmail;
