import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch } from "@reach/router";
import { ToastContainer, toast } from "react-toastify";
import PageContainer from "../components/pageContainer/PageContainer";
import NavBar from "../components/navbar";
import Footer from "../components/footer/Footer";
import { CompleteOnboardingBanner } from "../components/deals/completeOnboarding";
import "react-toastify/dist/ReactToastify.css";
import {
  hasKYCFinishedSelector,
  hasKYCStartedSelector,
  investorSelector,
  neverLoadedInvestorSelector,
  verifyInvestorStatusSelector,
} from "../state/investor/investor.selectors";
import TutorialModal from "../components/TutorialModal";
import { VerifyIdentityBanner } from "../components/deals/verifyIndentity/VerifyIdentityBanner";
import { PageContentContainer } from "@components/pageContainer/styled";
import { NavBarContainer, PageWrapper } from "./styled";
import RegulatoryBanner from "@components/RegulatoryBanner/RegulatoryBanner";
import { InlineLoader } from "@components/Loading/InlineLoader";
import VerifyInvestorProfileBanner from "../components/deals/verifyInvestorProfile/VerifyInvestorProfileBanner";
import VerifyAccountBanner from "../components/deals/verifyAccount/VerifyAccountBanner";
import { authSelector } from "../state/auth/auth.selectors";
import { isEmailVerificationEnabledSelector } from "../state/remoteConfiguration/remoteConfiguration.selectors";

const VerifyInvestorProfileModal = React.lazy(
  () =>
    import(
      "../components/deals/verifyInvestorProfile/VerifyInvestorProfileModal"
    )
);

const VerifyAccountModal = React.lazy(
  () => import("../components/deals/verifyAccount/VerifyAccountModal")
);

// TODO: Remove this layout component when we finish with the frontend refractory
// Add more pages to this array as you refactor then
const layoutlessPages = [
  "/signup",
  "/app/identity/*",
  "/app/login",
  "/app/signup",
  "/app/signup-apple",
  "/app/onboarding/*",
  "/app/forgot-password",
  "/app/reset-password",
  "/app/verify-email",
];

export const localStorageTutorialKey = "hasSeenTutorial";

const Layout: React.FC = ({ children }) => {
  const hasSeenTutorial = localStorage.getItem(localStorageTutorialKey);
  const [showTutorialModal, setShowTutorialModal] = useState<boolean>(
    hasSeenTutorial === "false"
  );
  const [showHowItWorksModal, setShowHowItWorksModal] =
    useState<boolean>(false);

  useEffect(() => {
    setShowTutorialModal(hasSeenTutorial === "false");
  }, [hasSeenTutorial]);

  const noLayout = layoutlessPages.some((route) => useMatch(route));
  const investor = useSelector(investorSelector);
  const auth = useSelector(authSelector);
  const neverLoadedInvestor = useSelector(neverLoadedInvestorSelector);
  const isOnboardingCompleted = investor?.onboardingStatus === "COMPLETED";

  const showCompleteOnboardingBanner =
    !isOnboardingCompleted && !neverLoadedInvestor;
  const isKYCPending = !useSelector(hasKYCStartedSelector);
  const showVerifyIdentityBanner =
    !useSelector(hasKYCFinishedSelector) && investor;

  const showVerifyInvestorProfileBanner = useSelector(
    verifyInvestorStatusSelector
  );

  const isEmailVerificationEnabled = useSelector(
    isEmailVerificationEnabledSelector
  );

  const showVerifyAccountBanner =
    isEmailVerificationEnabled && auth && !auth.isEmailVerified;

  const [renderVerifyProfileModal, setRenderVerifyProfileModal] =
    useState<boolean>(false);
  const [renderVerifyAccountModal, setRenderVerifyAccountModal] =
    useState<boolean>(false);

  const renderBanner = ({
    showVerifyAccountBanner,
    showVerifyInvestorProfileBanner,
    showCompleteOnboardingBanner,
    showVerifyIdentityBanner,
  }: {
    showVerifyAccountBanner: boolean;
    showVerifyInvestorProfileBanner: boolean;
    showCompleteOnboardingBanner: boolean;
    showVerifyIdentityBanner: boolean;
  }) => {
    switch (true) {
      case showVerifyAccountBanner:
        return (
          <VerifyAccountBanner
            onClickCTA={() => setRenderVerifyAccountModal(true)}
          />
        );
      case showVerifyIdentityBanner &&
        showCompleteOnboardingBanner &&
        showVerifyInvestorProfileBanner:
        return <CompleteOnboardingBanner />;
      case showCompleteOnboardingBanner && showVerifyIdentityBanner:
        return <CompleteOnboardingBanner />;
      case showCompleteOnboardingBanner:
        return <CompleteOnboardingBanner />;
      case showVerifyInvestorProfileBanner:
        return (
          <VerifyInvestorProfileBanner
            onClickCTA={() => {
              setRenderVerifyProfileModal(true);
            }}
          />
        );
      case showVerifyIdentityBanner:
        return <VerifyIdentityBanner verificationInProgress={!isKYCPending} />;
      default:
        return null;
    }
  };

  if (noLayout) {
    return (
      <>
        <ToastContainer
          autoClose={5000}
          closeOnClick
          hideProgressBar={false}
          position={toast.POSITION.TOP_CENTER}
        />
        {children}
      </>
    );
  }

  return (
    <PageWrapper>
      <TutorialModal
        title="Welcome to Cur8"
        description="Use our platform to Invest in institutional grade investments, track your portfolio and access a range of additional tools to help you manage your wealth effortlessly."
        buttonText="Get Started"
        videoUrl="https://www.youtube.com/embed/CpA8J4fcIJg?si=7lQf7Yf4QNP5bqb3"
        show={showTutorialModal}
        onClose={() => {
          localStorage.setItem(localStorageTutorialKey, "true");
          setShowTutorialModal(false);
        }}
      />
      <TutorialModal
        title="Invest in institutional grade investments"
        description="Access our carefully curated investments across V.C, real estate, fixed income and private equity"
        buttonText="Get Started"
        videoUrl="https://player.vimeo.com/video/743501081?h=6c36b9b9d2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        show={showHowItWorksModal}
        onClose={() => setShowHowItWorksModal(false)}
      />
      <Suspense fallback={<InlineLoader />}>
        <VerifyAccountModal
          show={renderVerifyAccountModal}
          onClose={() => {
            setRenderVerifyAccountModal(false);
          }}
        />
      </Suspense>
      {showVerifyInvestorProfileBanner && (
        <Suspense fallback={<InlineLoader />}>
          <VerifyInvestorProfileModal
            show={renderVerifyProfileModal}
            onClose={() => {
              setRenderVerifyProfileModal(false);
            }}
          />
        </Suspense>
      )}
      <ToastContainer
        autoClose={5000}
        closeOnClick
        hideProgressBar={false}
        position={toast.POSITION.TOP_CENTER}
      />
      <RegulatoryBanner />
      <PageContainer>
        {renderBanner({
          showVerifyAccountBanner,
          showCompleteOnboardingBanner,
          showVerifyIdentityBanner: Boolean(showVerifyIdentityBanner),
          showVerifyInvestorProfileBanner,
        })}
        <NavBarContainer>
          <NavBar showTutorialModal={() => setShowHowItWorksModal(true)} />
        </NavBarContainer>
        <PageContentContainer>{children}</PageContentContainer>
        <Footer />
      </PageContainer>
    </PageWrapper>
  );
};

export default Layout;
