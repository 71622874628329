import React, { FC } from "react";
import { Banner } from "@ifgengineering/component-library";

interface Props {
  onClickCTA?: VoidFunction;
}

export const VerifyInvestorProfileBanner: FC<Props> = ({ onClickCTA }) => (
  <Banner
    text={`You need to verify your investor status since it’s been 12 months.`}
    CTAText="Verify Now"
    onClickCTA={onClickCTA}
    testId={"verify-investor-profile-banner"}
  />
);

export default VerifyInvestorProfileBanner;
